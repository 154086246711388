export type ActionKey = 'Close' | 'GoToHomePage' | 'GoToContent' | 'Loading' | 'LogOn' | 'LogOff' | 'Open' | 'SeePrivacyPolicy' | 'Show';
import { Replacement } from '../dictionaryRepository';

interface DictionaryFunctions {
	get(key: ActionKey): string;
}

export const ActionItems = (getValue: (key: string, replacements?: Replacement[], defaultValue?: string) => string): DictionaryFunctions => {
	return {
		get: (key: ActionKey): string => {
			switch (key) {
				case 'Close':
					return getValue('Actions.Close', null, 'Luk');
				case 'GoToContent':
					return getValue('Actions.GoToContent', null, 'Gå til indhold');
				case 'GoToHomePage':
					return getValue('Actions.GoToHomePage', null, 'Gå til forsiden');
				case 'Loading':
					return getValue('Actions.Loading', null, 'Indhenter data');
				case 'LogOn':
					return getValue('Actions.LogOn', null, 'Log på');
				case 'LogOff':
					return getValue('Actions.LogOff', null, 'Log af');
				case 'Open':
					return getValue('Actions.Open', null, 'Åben');
				case 'SeePrivacyPolicy':
					return getValue('Actions.SeePrivacyPolicy', null, 'Se privatlivspolitik.');
				case 'Show':
					return getValue('Actions.Show', null, 'Vis');
			}
		},
	};
};
