export type AuthKey = 'Authenticated' | 'Unauthenticated' | 'LogOut';
import { Replacement } from '../dictionaryRepository';

interface DictionaryFunctions {
	get(key: AuthKey): string;
}

export const AuthItems = (getValue: (key: string, replacements?: Replacement[], defaultValue?: string) => string): DictionaryFunctions => {
	return {
		get: (key: AuthKey): string => {
			switch (key) {
				case 'Authenticated':
					return getValue('Auth.Authenticated', null, 'Logget ind');
				case 'Unauthenticated':
					return getValue('Auth.Unauthenticated', null, 'Ikke logget ind');
				case 'LogOut':
					return getValue('Auth.LogOut', null, 'Log ud');
			}
		},
	};
};
