export type PlaceholderKey = 'SearchPlaceholder';
import { Replacement } from '../dictionaryRepository';

interface DictionaryFunctions {
	get(key: PlaceholderKey): string;
}

export const PlaceholderItems = (getValue: (key: string, replacements?: Replacement[], defaultValue?: string) => string): DictionaryFunctions => {
	return {
		get: (key: PlaceholderKey): string => {
			switch (key) {
				case 'SearchPlaceholder':
					return getValue('Labels.SearchPlaceholder', null, 'Hvad søger du?');
			}
		},
	};
};
