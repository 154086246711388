import DownloadIcon from 'icons/download.svg';
import styles from './DownloadButton.module.scss';

export interface DownloadButtonProps {
	downloadUrl: string;
	label: string;
	onClick?: () => void;
}

export const DownloadButton = ({ downloadUrl, label, onClick }: DownloadButtonProps) => {
	return (
		<div className={styles.DownloadButton}>
			<a className={styles.DownloadButton_button} href={downloadUrl} download onClick={onClick && onClick}>
				<DownloadIcon /> <span className={styles.DownloadButton_buttonText}>{label} </span>
			</a>
		</div>
	);
};
