import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { useRouter } from 'next/router';
import { Preview } from 'ui/components/2-molecules/Preview';

export interface PreviewProps {
	url: string;
	children?: React.ReactNode;
}

export const PreviewFeature: React.FC<PreviewProps> = ({ children, url }) => {
	const { isPreview } = useRouter();
	const dictionary = useDictionaryContext();

	if (!isPreview) return <>{children}</>;

	return (
		<Preview url={url} inPreview={dictionary.getPreview('InPreview')} exitPreview={dictionary.getPreview('ExitPreview')}>
			{children}
		</Preview>
	);
};
