import { JWT } from 'next-auth/jwt';
/**
 * Takes a token, and returns a new token with updated
 * `accessToken` and `accessTokenExpires`. If an error occurs,
 * returns the old token and an error property
 */
export const refreshAccessToken = async (token: JWT, keyCloakIssuer: string): Promise<JWT> => {
	try {
		const url = `${keyCloakIssuer}/protocol/openid-connect/token`;

		const response = await fetch(url, {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			method: 'POST',
			body: new URLSearchParams({
				client_id: process.env.KEYCLOAK_CLIENT_ID,
				grant_type: 'refresh_token',
				refresh_token: token.refreshToken,
			}),
		});

		const refreshedTokens = await response.json();

		if (!response.ok) {
			throw refreshedTokens;
		}

		const currentEpochSeconds = Math.floor(Date.now() / 1000);
		return {
			...token,
			accessToken: refreshedTokens.access_token,
			accessTokenExpires: currentEpochSeconds + refreshedTokens.expires_in,
			refreshToken: refreshedTokens.refresh_token ?? token.refreshToken, // Fall back to old refresh token
		};
	} catch (error) {
		return {
			...token,
			error: 'RefreshAccessTokenError',
		};
	}
};
