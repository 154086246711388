export type LabelKey = 'BreadcrumbNavigation' | 'Menu' | 'NextPage' | 'Notice' | 'Page' | 'Pages' | 'Reference' | 'PreviousPage';
import { Replacement } from '../dictionaryRepository';

interface DictionaryFunctions {
	get(key: LabelKey): string;
}

export const LabelItems = (getValue: (key: string, replacements?: Replacement[], defaultValue?: string) => string): DictionaryFunctions => {
	return {
		get: (key: LabelKey): string => {
			switch (key) {
				case 'BreadcrumbNavigation':
					return getValue('Labels.BreadcrumbNavigation', null, 'Brødkrumme navigation');
				case 'Menu':
					return getValue('Labels.Menu', null, 'Menu');
				case 'NextPage':
					return getValue('Labels.NextPage', null, 'Næste side');
				case 'Notice':
					return getValue('Labels.Notice', null, 'Bemærk');
				case 'Page':
					return getValue('Labels.Page', null, 'Side');
				case 'Pages':
					return getValue('Labels.Pages', null, 'Sider');
				case 'Reference':
					return getValue('Labels.Reference', null, 'Ordre nr:');
				case 'PreviousPage':
					return getValue('Labels.PrevPage', null, 'Forrige side');
			}
		},
	};
};
