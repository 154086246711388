import { HttpStatusCode } from './HttpStatusCodes';

export class ApiError extends Error {
	public statusCode: number;

	constructor(name: string, statusCode: HttpStatusCode, message?: string) {
		super(message);
		this.name = name;
		this.statusCode = statusCode;
	}
}
