import { RequestInterface } from 'application/connectors/cms/_api';
import { getDictionaryFromCms } from 'application/connectors/cms/dictionaryConnector';
import {
	ActionItems,
	ActionKey,
	AuthItems,
	AuthKey,
	ErrorItems,
	ErrorKey,
	ExplainerItems,
	ExplainerKey,
	LabelItems,
	LabelKey,
	PlaceholderItems,
	PlaceholderKey,
	PreviewItems,
	PreviewKey,
} from './dictionaries';
export interface Replacement {
	name: string;
	value: string;
}

export interface DictionaryInterface {
	getLabel(key: LabelKey): string;
	getAction(key: ActionKey): string;
	getPreview(key: PreviewKey): string;
	getAuth(key: AuthKey): string;
	getExplainer(key: ExplainerKey): string;
	getPlaceholder(key: PlaceholderKey): string;
	getError(key: ErrorKey): string;
	items: Content.DictionaryItem[];
	culture: string;
}

export const DictionaryFactory = (items: Content.DictionaryItem[], culture: string): DictionaryInterface => {
	const getValue = (key: string, replacements?: Replacement[], defaultValue?: string) => {
		const dictionaryValue: Content.DictionaryItem = items.find((element) => element?.key.toLocaleLowerCase() === key.toLocaleLowerCase());
		let dictionaryString = dictionaryValue?.values?.find(
			(element) => element?.culture.toLocaleLowerCase() === culture.toLocaleLowerCase(),
		)?.value;
		if (!dictionaryString) {
			if (!defaultValue) {
				//Cannot find dictionary text and no default supplied - in development we use a default error text
				return process.env.NODE_ENV === 'development' ? `[DICTIONARY ${key} MISSING FOR ${culture}]` : null;
			} else {
				dictionaryString = defaultValue;
			}
		}

		if (!replacements) {
			return dictionaryString;
		}

		replacements.forEach((replacement) => {
			return (dictionaryString = dictionaryString.replace('${' + replacement.name + '}', replacement.value));
		});

		return dictionaryString;
	};

	return {
		getLabel: (key: LabelKey) => LabelItems(getValue).get(key),
		getAction: (key: ActionKey) => ActionItems(getValue).get(key),
		getAuth: (key: AuthKey) => AuthItems(getValue).get(key),
		getExplainer: (key: ExplainerKey) => ExplainerItems(getValue).get(key),
		getPlaceholder: (key: PlaceholderKey) => PlaceholderItems(getValue).get(key),
		getPreview: (key: PreviewKey) => PreviewItems(getValue).get(key),
		getError: (key: ErrorKey) => ErrorItems(getValue).get(key),
		items: items,
		culture: culture as string,
	};
};

export const getDictionaryItems = async ({ pageContext }: RequestInterface): Promise<Content.DictionaryItem[]> => {
	return await getDictionaryFromCms(pageContext);
};
