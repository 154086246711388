import { PageContextProps } from 'application/contexts/PageContext';
import { AxiosRequestConfig } from 'axios';
import { ApiError } from 'helpers/apiError';
import { ApiRedirect } from 'helpers/apiRedirect';
import { axiosInstance as axios } from 'helpers/axiosInstance';
import getConfig from 'next/config';

export interface RequestInterface {
	pageContext?: Partial<PageContextProps>;
	url?: string;
	query?: string;
	method?: 'GET' | 'POST';
}

const buildHeaders = ({ domain, preview, previewData }): { [key: string]: string } => {
	const headers = {};
	headers['Content-Type'] = 'application/json';
	headers['Hostname'] = domain;

	if (preview && typeof previewData === 'object') {
		headers['Authorization'] = previewData?.data?.auth;
	}

	return headers;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const queryCmsContent = async <TResponse = any>({ pageContext, method = 'POST', query, url }: RequestInterface): Promise<TResponse> => {
	const { preview, previewData } = pageContext ?? {};
	const { publicRuntimeConfig } = getConfig() ?? {};
	const { CONTENT_API_URL, DOMAIN_HOSTNAME, DOMAIN } = publicRuntimeConfig ?? {};
	if (!CONTENT_API_URL) throw new ApiError('CONTENT_API_URL:NOTDEFINED', 500, 'Content api url is not defined');
	if (!DOMAIN_HOSTNAME) throw new ApiError('DOMAIN_HOSTNAME:NOTDEFINED', 500, 'Domain hostname is not defined');

	if (url === 'umbraco') throw new ApiError('PageNotFound', 404, 'Page not found');

	const headers = buildHeaders({ domain: DOMAIN, preview, previewData });

	const contenturl = `${CONTENT_API_URL}${url ? url : ''}${preview ? '?preview=1' : ''}`;
	try {
		const options: AxiosRequestConfig = {
			url: contenturl,
			method: method,
			maxRedirects: 0,
			headers,
			data: {
				query,
			},
		};
		const response = await axios(options);
		let result;
		if (method === 'POST') {
			const { data, errors } = response.data;

			if (errors) {
				throw new Error(errors[0].message, {
					cause: {
						response,
						query,
					},
				});
			}
			result = data;

			result.preview = preview ? true : false;
			result.previewData = previewData ? previewData : null;
		}

		if (method === 'GET') {
			const { data } = response;
			result = data;
		}
		return result;
	} catch (error) {
		const message = error?.response?.data?.message ?? error;
		const statusCode = error?.response?.status || 500;
		const responseErrors = error?.cause?.response?.data?.errors;
		const numberOfErrors = responseErrors?.length;
		switch (statusCode) {
			case 301:
			case 302:
			case 307:
			case 308:
				throw new ApiRedirect(statusCode, error?.response?.headers?.location);
			case 404:
				throw new ApiError('PageNotFound', 404, 'Page not found');
			case 401: // "401 Unauthorized" really means "unauthenticated"
			case 403: // "403 Forbidden" really means "unauthorized"
				return error?.response?.data?.data;
			default:
				console.log(error.cause);
				throw new ApiError(
					'Could not grab content from CMS',
					statusCode,
					message + ` Number of errors: ${numberOfErrors}. Query: ${error?.cause?.query}. Url: ${contenturl}. Host: ${headers['Hostname']}`,
				);
		}
	}
};
