export type ErrorKey = HttpStatusCode | 'AuthenticationError' | 'PaymentCompletedNotConfirmed' | 'SessionExpired' | 'Unknown';
import { HttpStatusCode } from 'helpers/HttpStatusCodes';
import { Replacement } from '../dictionaryRepository';

interface DictionaryFunctions {
	get(key: ErrorKey): string;
}

export const ErrorItems = (getValue: (key: string, replacements?: Replacement[], defaultValue?: string) => string): DictionaryFunctions => {
	return {
		get: (key: ErrorKey): string => {
			switch (key) {
				case 'AuthenticationError':
					return getValue('Errors.AuthenticationError', null, 'Der opstod en uventet fejl under login. Prøv venligst igen');
				case HttpStatusCode.BadRequest:
					return getValue(
						'Errors.400',
						null,
						'Nets kunne desværre ikke behandle denne betaling. Kontakt venligst den givne gældsindehaver, eller prøv igen senere.',
					);
				case HttpStatusCode.InternalServerError:
					return getValue('Errors.500', null, 'Kunne ikke oprette forbindelse til serveren.');
				case HttpStatusCode.NotFound:
					return getValue(
						'Errors.404',
						null,
						'Serveren kunne ikke bekræfte den givne betaling. Dog kan betalingen være blevet gennemført. Kontakt support for mere info',
					);
				case HttpStatusCode.PaymentCanceled:
					return getValue('Errors.499', null, 'Betaling afbrudt.');
				case HttpStatusCode.PaymentRequired:
					return getValue('Errors.402', null, 'Betalingsloft nået. Du kan max betale regninger for 100.000,00 kr. ad gangen');
				case HttpStatusCode.PaymentRevoked:
					return getValue(
						'Errors.498',
						null,
						'Betaling blev desværre annuleret da serveren ikke kunne bekræfte din identitet. Prøv venligst igen.',
					);
				case HttpStatusCode.ServiceUnavailable:
					return getValue('Errors.503', null, 'Denne service er i øjeblikket ikke tilgængelig. Prøv igen på et senere tidspunkt.');
				case 'PaymentCompletedNotConfirmed':
					return getValue(
						'Errors.Unknown',
						null,
						'Din betaling kunne desværre ikke bekræftes. Bemærk, at betalingen er blevet gennemført hvis de modtager en bekræftelsesmail',
					);
				case HttpStatusCode.Unauthorized:
					return getValue('Errors.401', null, 'Der opstod fejl under bekræftelse af bruger. Log venligst på igen.');
				case 'SessionExpired':
					return getValue('Errors.SessionExpired', null, 'Dit login er desværre udløbet. Log venligst på igen');
				case 'Unknown':
					return getValue('Errors.Unknown', null, 'Der opstod en uventet fejl. Prøv venligst igen');
				default:
					return getValue('Errors.Default', null, 'Der opstod en uventet fejl. Prøv venligst igen');
			}
		},
	};
};
