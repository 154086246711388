import { Session } from 'next-auth';
import { getToken } from 'next-auth/jwt';
import { SessionProvider, useSession } from 'next-auth/react';
import React from 'react';

export interface AuthenticationContextProviderProps {
	children?: React.ReactNode;
	session: Session;
}

export const AuthenticationContextProvider: React.FC<AuthenticationContextProviderProps> = (props) => {
	return (
		<SessionProvider session={props.session} refetchOnWindowFocus={false}>
			{props.children}
		</SessionProvider>
	);
};

export const useAuthenticationContext = () => {
	const { data: loginSession } = useSession();
	return {
		isLoggedIn: null === loginSession,
		expires: loginSession?.expires ? new Date(loginSession.expires) : null,
		user: loginSession?.user,
		accessToken: loginSession?.accessToken,
		error: loginSession?.error,
	};
};

export const getAccessToken = async (req: Parameters<typeof getToken>[0]['req']): Promise<string | null> => {
	const token = await getToken({ req });
	return token?.accessToken ?? null;
};
