import { Button } from 'designsystem/components/1-atoms/Buttons';
import { RichText } from 'designsystem/components/1-atoms/RichText';
import { Modal } from 'designsystem/components/2-molecules/Modal';
import { signIn, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { signoutPage } from 'pages/api/auth/[...nextauth]';
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

export const IdleHandler = (content: Content.DebtOverviewPage) => {
	const { idleUserHeading, idleUserRichText, idleUserButtonText } = content?.properties ?? {};

	const [idle, setIdle] = useState(false);

	const { data: session, update } = useSession();
	const router = useRouter();

	const oneSecond = 1000;

	const onMessage = (message: 'idle' | 'refetch' | 'signout') => {
		if (message === 'idle') setIdle(true);
		if (message === 'refetch') {
			activate();
			signIn('keycloak');
		}
		if (message === 'signout') router.push(signoutPage);
	};

	const signOutTime = new Date(session?.expires).getTime();
	// react-idle-timer timeout is not relevant in our use-case but it is required to define.
	// The session expirary + warningTimeBeforeSignOut is what dictates when a user is idle.
	const timeout = oneSecond * 60 * 30; // 30 minutes
	const warningTimeBeforeSignOut = oneSecond * 60 * 3; // 3 minutes

	const onIdle = () => {
		setIdle(true);
		message('idle');
	};

	const { activate, message } = useIdleTimer({
		onIdle,
		onMessage,
		timeout: timeout,
		crossTab: true,
		syncTimers: 500,
	});

	const handleClose = () => {
		setIdle(false);
		update(session);
		activate();
		message('refetch');
	};

	useEffect(() => {
		if (!session?.expires) return;
		const signOutTime = new Date(session.expires).getTime() - 1000 * 60; // 1 minute before session expires
		const interval = setInterval(() => {
			const currentTime = new Date().getTime();
			if (currentTime > signOutTime) message('signout', true);

			if (idle) return;
			const warningTime = currentTime + warningTimeBeforeSignOut;
			if (warningTime >= signOutTime) onIdle(); // Fallback warning
		}, 7000);

		return () => {
			clearInterval(interval);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router, session?.expires, signOutTime, idle]);

	return (
		<Modal onClose={handleClose} header={idleUserHeading} show={idle} noCloseButton>
			<RichText content={idleUserRichText} />
			<Button onClick={handleClose}>{idleUserButtonText}</Button>
		</Modal>
	);
};
