import classNames from 'classnames';
import { Icon, IconSize } from 'components/1-atoms/Media';
import * as React from 'react';
import { Button, ButtonProps } from '../Button';
import styles from './IconButton.module.scss';

export type IconButtonProps = React.ComponentProps<'button'> &
	ButtonLabelProps & {
		icon: React.ReactNode;
		iconSize?: IconSize;
		highlighted?: boolean;
		buttonStyle?: ButtonProps['buttonStyle'];
		buttonFontWeight?: ButtonProps['buttonFontWeight'];
		buttonSize?: ButtonProps['buttonSize'];
		hidden?: ButtonProps['hidden'];
	};

type ButtonWithLabel = {
	label: string;
	ariaLabel?: never;
};
type ButtonWithoutLabel = {
	label?: never;
	ariaLabel: string;
};

export type ButtonLabelProps = ButtonWithLabel | ButtonWithoutLabel;

export const IconButton: React.FC<IconButtonProps> = ({
	label,
	ariaLabel,
	buttonStyle = 'primary-light',
	buttonFontWeight,
	buttonSize,
	icon,
	iconSize = 'xs',
	highlighted = false,
	className,
	onClick,
	...rest
}) => (
	<div className={classNames(styles.IconButton, highlighted && styles.IconButton___highlighted, className)}>
		<Button
			className={styles.IconButton_button}
			buttonStyle={buttonStyle}
			buttonFontWeight={buttonFontWeight}
			buttonSize={buttonSize ? buttonSize : 'medium'}
			onClick={onClick}
			aria-label={ariaLabel}
			{...rest}
		>
			<Icon className={styles.IconButton_icon} size={highlighted ? null : iconSize}>
				{icon}
			</Icon>
			{label && label}
			{rest?.children}
		</Button>
	</div>
);
