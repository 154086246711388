import { PreviewFeature } from 'features/Modules/Preview';
import React, { useContext } from 'react';

export class PageContextProps {
	private _pageContent: Content.PageContent;

	public constructor(pageContent: Content.PageContent) {
		this._pageContent = pageContent;
	}

	public get template(): string {
		return this._pageContent?.page?.template;
	}

	public get name(): string {
		return this._pageContent?.page?.name;
	}

	public get id(): number {
		return this._pageContent?.page?.id;
	}

	public get url(): string {
		return this._pageContent?.page?.url;
	}

	public get content(): Content.Page['properties'] {
		return this._pageContent?.page?.properties;
	}

	public get footer(): Content.PageContent['footer'] {
		return this._pageContent?.footer;
	}

	public get host(): Content.PageContent['host'] {
		return this._pageContent?.host;
	}

	public get pageLanguageVersions(): Models.PageLanguageVersion[] {
		return this._pageContent?.page?.pageLanguageVersions;
	}

	public get preview(): boolean {
		return this._pageContent?.preview ?? false;
	}

	public get previewData(): Models.PreviewData {
		return this._pageContent?.previewData;
	}
}

export const PageContext = React.createContext<Partial<PageContextProps>>(new PageContextProps(null));

export interface PageContextProviderProps {
	content: Content.PageContent;
	children?: React.ReactNode;
	host: string;
}
export const PageContextProvider: React.FC<PageContextProviderProps> = ({ content, children, host }) => {
	content ? (content.host = host) : content;
	const pageContext: PageContextProps = new PageContextProps(content);
	return (
		<PageContext.Provider value={pageContext}>
			<PreviewFeature url={content?.page?.url}>{children}</PreviewFeature>
		</PageContext.Provider>
	);
};

export function usePageContext(): Partial<PageContextProps> {
	return useContext(PageContext);
}
