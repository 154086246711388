import { AuthenticationContextProvider } from 'application/contexts/AuthenticationContext';
import { DictionaryContextProvider } from 'application/contexts/DictionaryContext';
import { PageContextProvider } from 'application/contexts/PageContext';
import { IdleHandler } from 'features/Handlers/IdleHandler';
import { NextPage } from 'next';
import type { AppProps as NextAppProps } from 'next/app';
import { StrictMode } from 'react';
import 'ui/styles/globals.scss';

/**
 * The `DXPContentPage` type is the result of _app.tsx's modifications to page props.
 */
export type DXPContentPage<TProps extends { content?: Content.PageContent }> = NextPage<TProps['content'] & Omit<TProps, 'content' | 'session'>>;

const App: React.FC<NextAppProps> = ({ Component, pageProps }) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const { content, dictionary, session, host } = (pageProps as any) ?? {};
	const { page } = content ?? {};
	const { culture } = page ?? {};

	return (
		<StrictMode>
			<AuthenticationContextProvider session={session}>
				<DictionaryContextProvider dictionary={dictionary ?? []} culture={culture}>
					<PageContextProvider content={content} host={host}>
						<>
							<IdleHandler {...content?.page} />
							<Component {...content} {...pageProps} />
						</>
					</PageContextProvider>
				</DictionaryContextProvider>
			</AuthenticationContextProvider>
		</StrictMode>
	);
};

export default App;
